import axios from 'axios';
// import Cookies from 'js-cookie';
import router from '../../router';
import { twilioServer } from '../../env-handler';

const state = {
  jwtToken: localStorage.jwtToken,
  status: null,
  hasLoggedOnce: false,
  admin: false,
  email: localStorage.email,
  googleCalendar: localStorage.googleCalendar,
  id: null,
  isLoggedin: false,
};

const getters = {
  // Return true if user logged in
  getAuthToken: (state) => state.jwtToken,
  isLoggedin: (state) => state.isLoggedin,
  hasLoggedOnce: (state) => !!state.email && !!state.jwtToken,
  authStatus: (state) => state.status,
  getUserEmail: (state) => state.email,
  getUser: ({ admin, email, id }) => {
    return { admin, email, id };
  },
  getGoogleCalendar: (state) => state.googleCalendar,
};

const actions = {
  signout: ({ commit, dispatch }) => {
    // Cookies.remove('session');
    localStorage.jwtToken = '';
    localStorage.email = '';
    // Disconnect from Taskrouter
    dispatch('workerSignout');

    commit('signout');
    commit('user_reset');
    /* if (router.currentRoute.name != 'signin') {
      router.push({ name: 'signin' });
    } */
  },
  offline: ({ commit }) => {
    commit('signout');
    router.push({ name: 'signin' });
  },
  fetchCurrentUser: async (
    { state, commit, dispatch },
    { relogin = false, route = 'taches' }
  ) => {
    if (state.jwtToken) {
      await axios
        .get(`${twilioServer.baseUrl}/auth/users/currentuser`, {
          headers: {
            Authorization: `Bearer ${localStorage.jwtToken}`,
          },
        })
        .then((res) => {
          const { currentUser } = res.data;
          if (currentUser) {
            const { admin, email, id, worker } = currentUser;
            // Check if user have a worker link already
            if (worker) {
              dispatch('fetchWorker');
            }
            commit('signin_success', { admin, email, id });

            if (relogin) {
              router.push({ name: route });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  signin: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      const { email, password, route = 'taches' } = user;
      commit('signin');
      axios
        .post(`${twilioServer.baseUrl}/auth/users/signin`, { email, password })
        .then((res) => {
          const { admin, email, id, worker, jwtToken } = res.data;
          // Save jwt token in to persistant storage
          commit('setJwtToken', jwtToken);

          // Check if user have a worker link already
          if (worker) {
            dispatch('fetchWorker');
          }
          commit('signin_success', { admin, email, id });
          router.push({ name: route });
          resolve();
        })
        .catch((error) => {
          commit('signin_error');
          commit('user_reset');
          reject(error.response);
        });
    });
  },
  googleCalendar: ({ commit }, googleCalendar) => {
    commit('setGoogleCalendar', googleCalendar);
  },
};

const mutations = {
  signin: (state) => {
    state.status = 'loading';
  },
  setJwtToken: (state, jwtToken) => {
    localStorage.jwtToken = jwtToken;
    state.jwtToken = jwtToken;
  },
  signin_success: (state, { admin, email, id }) => {
    localStorage.email = email;
    state.status = 'success';
    state.admin = admin;
    state.email = email;
    state.id = id;
    state.hasLoggedOnce = true;
    state.isLoggedin = true;
  },
  signin_error: (state) => {
    state.status = 'error';
  },
  signout: (state) => {
    state.status = null;
    state.isLoggedin = false;
  },
  user_reset: (state) => {
    state.jwtToken = null;
    state.admin = false;
    state.email = null;
    state.id = null;
    state.hasLoggedOnce = false;
    state.isLoggedin = false;
  },
  setGoogleCalendar: (state, googleCalendar) => {
    localStorage.googleCalendar = googleCalendar;
    state.googleCalendar = googleCalendar;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
