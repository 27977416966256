<template>
  <div class="row-call-tab">
    <div>
      <span style="color: grey;"
        >Service: <strong>{{ getSelectedTask.attributes.service }}</strong>
      </span>
      <h3>{{ getSelectedTask.attributes.title }}</h3>
      <span>{{ getSelectedTask.attributes.text }}</span>
    </div>
    <div class="incoming-zone">
      <div class="image-container">
        <img :src="getImageSrc" width="100em" class="image" />
        <div class="image-overlay"></div>
      </div>
      <span class="incoming-h1-margin">
        Client: <strong class="hover-link"> {{ localPhoneNumber }}</strong>
      </span>
    </div>
    <div v-show="incomingCall">
      <template v-if="getCallStatus === 'pending'">
        <el-button
          type="success"
          round
          @click="() => answerCall(getSelectedTask.sid)"
        >
          Répondre
        </el-button>
        <el-button
          type="danger"
          icon="el-icon-close-notification"
          circle
          @click="() => ignoreCall(getSelectedTask.sid)"
          disabled
        ></el-button>
      </template>
      <template v-else-if="getCallStatus === 'open'">
        <el-button
          type="danger"
          round
          @click="() => closeCall(getSelectedTask.sid)"
        >
          Raccrocher
        </el-button>
        <el-button
          :type="getHoldStatus ? 'warning' : 'success'"
          round
          @click="putCallOnHold"
          :icon="getHoldStatus ? 'el-icon-video-pause' : 'el-icon-video-play'"
        >
        </el-button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PhoneNumber from 'awesome-phonenumber';

export default {
  name: 'CallTabsPane',
  computed: {
    ...mapGetters([
      'getSelectedTask',
      'getIncomingCallTaskId',
      'getCallStatus',
      'getMuteStatus',
      'getHoldStatus',
      'getIncomingCallSid',
    ]),
    getImageSrc() {
      if (
        this.getSelectedTask.attributes.channel == 'callback' ||
        this.getSelectedTask.attributes.channel == 'call'
      ) {
        return '/assets/img/outgoing-call.svg';
      }
      return '/assets/img/incoming-call.svg';
    },
    localPhoneNumber() {
      // const pn = new PhoneNumber(this.getSelectedTask.attributes.from);
      const pn = new PhoneNumber(this.getSelectedTask.attributes.phone);
      return pn.getNumber('national');
    },
    incomingCall() {
      if (this.getSelectedTask.sid == this.getIncomingCallTaskId) {
        return true;
      }
      return false;
    },
    onHoldMessage() {
      if (this.getHoldStatus) {
        return "Reprendre l'appel";
      }
      return "Mettre l'appel en attente";
    },
  },
  methods: {
    ...mapActions([
      'answerCall',
      'closeCall',
      'ignoreCall',
      'rejectCall',
      'ParticipantCallOnHold',
      'reqConferenceByName',
      'addConferenceToTask',
    ]),
    putCallOnHold: async function() {
      let conference = this.getSelectedTask.attributes.conference;

      if (!conference) {
        const res = await this.reqConferenceByName(this.getIncomingCallSid);
        conference = {
          sid: res.sid,
          participants: { customer: 'customer' },
        };
        this.addConferenceToTask({
          taskId: this.getSelectedTask.sid,
          conference,
        });
      }

      this.ParticipantCallOnHold({
        conferenceSid: conference.sid,
        callSid: conference.participants.customer,
        hold: !this.getHoldStatus,
      });
    },
  },
};
</script>

<style>
.large-icon {
  font-size: px;
}
.row-call-tab {
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
}
.incoming-zone {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.image-container {
  position: relative;
}
.image {
  display: block;
}
.image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #ffffff;
}
.incoming-zone:hover .image-overlay {
  opacity: 0.3;
}
.incoming-zone:hover .hover-link {
  color: #409eff;
  text-decoration: underline #409eff;
}
.incoming-img-margin {
  margin-top: 30px;
  margin-bottom: 10px;
}
.incoming-h1-margin {
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>
