export const version = 'v1.0.1';

export const twilioServer = {
  baseUrl: 'https://callcenter.homesecours.fr/api',
  // baseUrl: 'http://localhost',
};

export const twilioEnv = {
  appSid: 'APf13a239586c9ea23b6217a0e0edc7e6f',
  phoneNumber: '+33755536896',
};

export const taskRouterEnv = {
  connectActivitySid: 'WA5f83a7764e19884829195ca5ceebf4e8',
  disconnectActivitySid: 'WA55b7747375caafa1211e1864dd695759',
  busyActivitySid: 'WA94713c8846156ff8fbb3c618dcd99f00',
  debug: true,
  workflows: {
    default: {
      // Twilio Contact Center Workflow
      sid: 'WWe3066228a0fc0bc0464485a1b1eb839e',
      name: 'Twilio Contact Center Workflow',
    },
    WWe3066228a0fc0bc0464485a1b1eb839e: {
      // Twilio Contact Center Workflow
      sid: 'WWe3066228a0fc0bc0464485a1b1eb839e',
      name: 'Twilio Contact Center Workflow',
    },
    WW368811cadb2cfde4153dca9f1e080f78: {
      // Twilio Contact Center Workflow
      sid: 'WW368811cadb2cfde4153dca9f1e080f78',
      name: 'Assign to anyone',
    },
    WW7d726e2a9e8d3bbb6ba069baffd9530b: {
      // Twilio Contact Center Workflow
      sid: 'WW7d726e2a9e8d3bbb6ba069baffd9530b',
      name: 'Assign to someone',
    },
  },
  // TODO: Fetch TaskQueues from server
  TaskQueues: {
    WQdb8ed4602bd3cef5215c55f53be1db6f: {
      name: 'default',
      friendlyName: 'Default',
      sid: 'WQdb8ed4602bd3cef5215c55f53be1db6f',
      icon: 'file-tray-full-outline',
      iconColor: 'white',
      iconBackground: '#409EFF',
    },
    WQf251c378d41319706723643aea04c3ca: {
      name: 'phone',
      friendlyName: 'Appel',
      sid: 'WQf251c378d41319706723643aea04c3ca',
      icon: 'call-outline',
      iconColor: 'white',
      iconBackground: '#409EFF',
    },
    WQcd48b18a1b9201665cc48abf66d407a0: {
      name: 'chat',
      friendlyName: 'Chat',
      sid: ' WQcd48b18a1b9201665cc48abf66d407a0',
      icon: 'chatbubble-ellipses-outline',
      iconColor: 'white',
      iconBackground: '#409EFF',
    },
    /* TCb56cc0b4d542d060d3a7e8ce58e813e5: {
      name: 'sms',
      friendlyName: 'SMS',
      sid: 'TCb56cc0b4d542d060d3a7e8ce58e813e5',
      icon: 'chatbox-outline',
      iconColor: 'white',
      iconBackground: '#409EFF',
    }, */
    WQ55b7140d9d5358f35239e0551c4f07e1: {
      name: 'video',
      friendlyName: 'Video',
      sid: 'WQ55b7140d9d5358f35239e0551c4f07e1',
      icon: 'videocam-outline',
      iconColor: 'white',
      iconBackground: '#409EFF',
    },
    /*     TC55987246b94dfb30be11108bf4fe1cda: {
      name: 'calendrier',
      friendlyName: 'Calendrier',
      sid: 'TC55987246b94dfb30be11108bf4fe1cda',
      icon: 'calendar-outline',
      iconColor: 'white',
      iconBackground: '#409EFF',
    }, */
    WQ5c36c7d7317a5b6a31f8412e791d8f39: {
      name: 'callback',
      friendlyName: 'Rappel',
      sid: 'WQ5c36c7d7317a5b6a31f8412e791d8f39',
      icon: 'arrow-undo-outline',
      iconColor: 'white',
      iconBackground: '#409EFF',
    },
  },
};
