<template>
  <el-header height="32px">
    <ion-icon name="menu-outline" class="icon1" @click="toggleLeftDrawer" />
    <el-tag size="small">{{ version }}</el-tag>
  </el-header>
</template>

<script>
import { mapActions } from 'vuex';
import { version } from '../env-handler';

export default {
  name: 'AppTopBar',
  data() {
    return {
      version,
    };
  },
  computed: {
    moment() {
      return moment;
    },
  },
  methods: {
    ...mapActions(['toggleLeftDrawer']),
  },
};
</script>

<style>
.el-header {
  background-color: #f2f6fc;
  display: flex;
  justify-content: space-between;
  align-content: stretch;
  border: 1px solid #e6e6e6;
  padding: 0px 12px 0px 12px !important;
}
.icon1 {
  font-size: 24px;
  color: #90938f;
  margin-left: 6px;
  cursor: pointer;
  align-self: center;
}
.el-header .el-tag {
  margin-bottom: auto;
  margin-top: auto;
}
</style>
