import Vue from 'vue';
import App from './App';
import store from './store';
import router from './router';
import moment from 'moment';
import './plugins/element.js';
import AddToCalendar from 'vue-add-to-calendar';

// Set global fr locale
moment.locale('fr');

const start = async () => {
  //Run get CurrentUser before starting app
  // await store.dispatch('fetchCurrentUser', {});

  Vue.config.ignoredElements = ['ion-icon'];
  Vue.use(AddToCalendar);
  new Vue({
    store,
    router,
    render: (h) => h(App),
  }).$mount('#app');
};

start();
