<template>
  <div>Dashboard</div>
</template>

<script>
export default {
  name: 'DashBoard',
};
</script>

<style></style>
