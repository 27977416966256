<template>
  <div class="phone-dialog">
    <div class="phone-controller">
      <section class="phone">
        <el-input v-model="phoneNumber" class="phone-number" ref="refphone">
          <el-select v-model="contryCode" slot="prepend">
            <el-option label="+596" value="MQ"></el-option>
            <el-option label="+590" value="GP"></el-option>
            <el-option label="+33" value="FR"></el-option>
          </el-select>
        </el-input>
        <div class="button-controller">
          <el-button class="button-call" @click="onClickCallButton">
            APPELER
          </el-button>
        </div>
        <div class="num-pad">
          <div class="digit" @click="() => onDigitPress('1')">1</div>
          <div class="digit" @click="() => onDigitPress('2')">2</div>
          <div class="digit" @click="() => onDigitPress('3')">3</div>

          <div class="digit" @click="() => onDigitPress('4')">4</div>
          <div class="digit" @click="() => onDigitPress('5')">5</div>
          <div class="digit" @click="() => onDigitPress('6')">6</div>

          <div class="digit" @click="() => onDigitPress('7')">7</div>
          <div class="digit" @click="() => onDigitPress('8')">8</div>
          <div class="digit" @click="() => onDigitPress('9')">9</div>

          <div class="digit" @click="() => onDigitPress('*')">*</div>
          <div class="digit" @click="() => onDigitPress('0')">0</div>
          <div class="digit" @click="() => onDigitPress('#')">#</div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PhoneNumber from 'awesome-phonenumber';

export default {
  name: 'phoneBooth',
  data() {
    return {
      phoneNumber: '',
      contryCode: 'MQ',
    };
  },
  methods: {
    ...mapActions(['createWorkerTaskCall']),
    onClickCallButton: function() {
      const intNumber = new PhoneNumber(this.phoneNumber, this.contryCode);

      if (intNumber.isValid) {
        console.log('Calling: ', intNumber.getNumber());
        this.createWorkerTaskCall(intNumber.getNumber());
      }
    },
    onDigitPress: function(dmtf) {
      this.phoneNumber = this.phoneNumber + dmtf;
      //TODO: If on CALL play DMTF sound
    },
  },
};
</script>

<style>
.phone-dialog .el-dialog .el-dialog__body {
  padding: unset;
}
.phone-controller {
  width: 300px;
}
.phone-controller .phone {
  background-color: #ffffff;
}
.phone-controller .phone-number {
  box-sizing: border-box;
  margin-top: 10px;
  padding: 5px 15px 5px 15px;
  color: #333333;
  overflow: hidden;
}

.phone-controller .phone-number .el-select .el-input {
  width: 80px;
  font-size: 15px;
  font-weight: bold;
}

.phone-controller .phone-number .el-select .el-input .el-input__inner {
  padding: 0px 30px 0px 10px;
}

.phone-controller .phone-number .el-input-group__prepend {
  background-color: #fff;
}

.el-input-group > .el-input__inner {
  font-size: 18px;
  color: black;
}

.phone-controller .num-pad {
  overflow: auto;
  padding: 10px 50px 10px 50px;
}
.phone-controller .digit {
  float: left;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #ddd;
  font-size: 1.6em;
  margin-left: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  cursor: pointer;
  color: #787878;
  border-radius: 3px;
}

.phone-controller .digit:nth-child(3n + 1) {
  margin-left: 5px;
}

.phone-controller .digit:nth-child(1n + 10) {
  margin-bottom: 0;
}

.phone-controller .digit:hover {
  background-color: #787878;
  color: white;
}

.phone-controller .call-control {
  text-align: center;
  padding-bottom: 10px;
}

.phone-controller .button-controller {
  padding: 5px 15px 5px 15px;
}
.phone-controller .button-controller .button-call {
  width: 100%;
  border: 1px solid #07ca82;
  font-size: 1.4em;
  background-color: #07ca82;
  color: white;
}

.phone-controller .button-controller .button-call[disabled] {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ddd;
  font-size: 1.2em;
  background-color: #ddd;
  color: white;
}
</style>
