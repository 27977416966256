<template>
  <el-container class="el-container-main-left-panel">
    <template v-if="showTaskList">
      <el-collapse width="100%" :value="selectedCollapse">
        <el-collapse-item name="reserved" :disabled="!getReservedTaskList">
          <template slot="title">
            <span class="el-tabs__item is-top uppercase">à traiter</span>
          </template>
          <TaskListItem
            v-for="task in getReservedTaskList"
            :key="task.sid"
            :task="task"
            :selectedTaskId="selectedTaskId"
          />
        </el-collapse-item>
        <el-collapse-item name="wrapping" :disabled="!getWrappingTaskList">
          <template slot="title">
            <span class="el-tabs__item is-top uppercase">à clôturer</span>
          </template>
          <TaskListItem
            v-for="task in getWrappingTaskList"
            :key="task.sid"
            :task="task"
            :selectedTaskId="selectedTaskId"
          />
        </el-collapse-item>
        <el-collapse-item name="completed" v-if="getCompletedTaskList">
          <template slot="title">
            <span class="el-tabs__item is-top uppercase">déjà réalisées</span>
          </template>

          <TaskListItem
            v-for="task in getCompletedTaskList"
            :key="task.sid"
            :task="task"
            :selectedTaskId="selectedTaskId"
          />
        </el-collapse-item>
        <el-collapse-item name="canceled" v-if="getCanceledTaskList">
          <template slot="title">
            <span class="el-tabs__item is-top uppercase">Taches annulées</span>
          </template>
          <TaskListItem
            v-for="task in getCanceledTaskList"
            :key="task.sid"
            :task="task"
            :selectedTaskId="selectedTaskId"
          />
        </el-collapse-item>
      </el-collapse>
    </template>

    <template v-else>
      <el-main class="el-main-page">
        <span class="no-task-uppercase"
          >Vous n'avez pas actuellement de taches actives</span
        >
      </el-main>
    </template>
  </el-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { taskRouterEnv } from '../../env-handler';
import { pickBy, isEmpty } from 'lodash';

import TaskListItem from '../../components/TaskListItem';
import moment from 'moment';

export default {
  name: 'AgentTaskList',
  components: {
    TaskListItem,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['showTaskList', 'getTaskList', 'getSelectedTask']),
    selectedTaskId() {
      if (!this.getSelectedTask) {
        return '';
      }
      return this.getSelectedTask.sid;
    },
    selectedCollapse() {
      const collapse = [];

      if (this.getReservedTaskList) {
        collapse.push('reserved');
      }

      if (this.getWrappingTaskList) {
        collapse.push('wrapping');
      }

      if (this.getCompletedTaskList) {
        collapse.push('completed');
      }

      if (this.getCanceledTaskList) {
        collapse.push('canceled');
      }

      return collapse;
    },
    getReservedTaskList() {
      const list = pickBy(
        this.getTaskList,
        (task) => task.assignmentStatus == 'reserved'
      );
      if (isEmpty(list)) {
        return null;
      }
      return list;
    },
    getWrappingTaskList() {
      const list = pickBy(
        this.getTaskList,
        (task) =>
          task.assignmentStatus == 'wrapping' ||
          task.assignmentStatus == 'assigned'
      );
      if (isEmpty(list)) {
        return null;
      }
      return list;
    },
    getCompletedTaskList() {
      const list = pickBy(
        this.getTaskList,
        (task) => task.assignmentStatus == 'completed'
      );
      if (isEmpty(list)) {
        return null;
      }
      return list;
    },
    getCanceledTaskList() {
      const list = pickBy(
        this.getTaskList,
        (task) => task.assignmentStatus == 'canceled'
      );
      if (isEmpty(list)) {
        return null;
      }
      return list;
    },
  },
  methods: {
    ...mapActions(['selectTask', 'showCallPad']),
    taskRender: function(taskQueueSid) {
      return taskRouterEnv.TaskQueues[taskQueueSid];
    },
    fromNow: function(date) {
      // reservation.task.dateCreated
      return moment(date).fromNow();
    },
  },
};
</script>

<style>
.el-container-main-left-panel {
  height: 100%;
  display: block !important;
}
.el-main-page {
  height: 100%;
}
.no-task-uppercase {
  margin: 0px 10% 20px 10%;
  text-transform: uppercase;
  font-size: 1.6em;
  text-align: center;
}
.task-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}
.task-icon {
  font-size: 32px;
  align-items: center;
  display: flex;
}
.task-icon-large {
  font-size: 54px;
  align-items: center;
  display: flex;
}
.task-icon-div {
  align-items: center;
  display: flex;
  border-right: 1px solid #e6e6e6;
}
.task-text-div {
  display: flex;
  padding-left: 10px;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.task-validation-resa {
  width: 120px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.separation-panel {
  padding: 5px;
  border: 1px solid #e6e6e6;
  background-color: #f2f6fc;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.not-active-item {
  background-color: #f2f6fc;
}
.el-collapse-item__header {
  height: 40px !important;
  line-height: 40px !important;
}
.collapse-marin-left {
  margin-left: 20px;
}
.uppercase {
  text-transform: uppercase;
}
</style>
