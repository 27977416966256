<template>
  <el-form :model="login" :rules="rules" ref="loginForm" label-width="120px">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <h2>Serveur Home Secours</h2>
        Connexion au serveur
      </div>
      <el-form-item label="Email" prop="email">
        <el-input v-model="login.email" :readonly="hasLoggedOnce"></el-input>
      </el-form-item>
      <el-form-item
        label="Mot de passe"
        prop="password"
        v-show="!hasLoggedOnce"
      >
        <el-input
          type="password"
          v-model="login.password"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('loginForm')"
          >Connexion</el-button
        >
        <el-button
          type="text"
          @click="resetForm('loginForm')"
          v-show="hasLoggedOnce"
          >Changer d'utilisateur</el-button
        >
      </el-form-item>
      <el-alert :title="error" type="error" v-show="errorAlert"> </el-alert>
    </el-card>
  </el-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LoginPage',
  data() {
    return {
      login: {
        email: '',
        password: '',
      },
      rules: {
        email: [
          {
            required: true,
            message: 'Votre email est requis',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'Un mot de passe est requis',
            trigger: 'blur',
          },
        ],
      },
      valid: true,
      error: '',
    };
  },
  computed: {
    ...mapGetters(['isLoggedin', 'hasLoggedOnce', 'getUserEmail']),
    errorAlert() {
      return !!this.error;
    },
  },
  mounted: function() {
    if (this.hasLoggedOnce) {
      this.login.email = this.getUserEmail;
    }
  },
  methods: {
    ...mapActions(['fetchCurrentUser', 'signin', 'signout']),
    submitForm(formName) {
      if (this.hasLoggedOnce) {
        this.fetchCurrentUser({ relogin: true });
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.validSignin();
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    validSignin: function() {
      this.signin(this.login).catch((err) => {
        const { status } = err;
        if (status == 400) {
          this.error = 'Email ou mot de passe incorrect';
        } else {
          this.error = "Erreur de connexion au serveur d'authentification";
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.signout();
      this.error = '';
    },
  },
};
</script>

<style>
.box-card {
  width: 480px;
}
.el-form {
  margin-bottom: 20vh;
}
</style>
