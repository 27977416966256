import { twilioEnv, twilioServer, taskRouterEnv } from '../../env-handler';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import TaskRouter from '../../services/taskrouter-helper';

let taskRouter = null;

const state = {
  endpointId: uuidv4(),
  token: null,
  taskRouterReady: false,
  taskRouterError: null,
  activityList: null,
  activityName: null,
  activitySid: null,
  attributes: null,
  available: false,
  friendlyName: null,
  sid: null,
};

const getters = {
  taskRouterStatus: (state) => state.available,
  workerIsConnected: (state) => !!state.sid,
  getWorkerFriendlyName: (state) => state.friendlyName,
  getWorkerContacUri: (state) => state.attributes.contact_uri,
  getWorkerSid: (state) => state.sid,
  getActivityList: (state) => state.activityList,
  getWorkerActivitySid: (state) => state.activitySid,
  getWorkerAvailable: (state) => state.available,
  dialogHorsLigne: (state) =>
    state.activitySid === 'WA94713c8846156ff8fbb3c618dcd99f00',
};

const actions = {
  fetchWorker: ({ state, commit, dispatch }) => {
    axios
      .post(
        `${twilioServer.baseUrl}/workers/tokens`,
        {
          applicationSid: twilioEnv.appSid,
          endpointId: state.endpointId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.jwtToken}`,
          },
        }
      )
      .then((res) => {
        const { tokens } = res.data;

        if (!tokens) {
          throw new Error('Pas de tokens pour à cet utilisateur');
        }
        commit('setWorkerToken', tokens.capability);

        // Initialize worker twilio phone
        dispatch('InitializePhone', tokens.access);

        taskRouter = new TaskRouter(tokens.capability, commit, dispatch);
      })
      .catch((err) => {
        commit('errorTaskRouter', err);
        console.error(err);
      });
  },
  changeWorkerActivity: ({ commit }, updateActivitySid) => {
    taskRouter.setWorkerActivity(updateActivitySid, (err) => {
      if (err) {
        commit('errorTaskRouter', err);
        throw new Error(err);
      }
    });
  },
  workerSignout: ({ commit }) => {
    commit('resetWorker');
    if (taskRouter) {
      taskRouter.setWorkerActivity(
        taskRouterEnv.disconnectActivitySid,
        (err) => {
          if (err) {
            commit('errorTaskRouter', err);
            throw new Error(err);
          }
        }
      );
    }
  },
  taskRouterCompletedTask: ({ commit }, taskId) => {
    taskRouter.setCompleteTask(taskId, (err) => {
      if (err) {
        commit('errorTaskRouter', err);
        throw new Error(err);
      }
    });
  },
  renewWorkerToken: ({ dispatch, commit }) => {
    axios
      .post(
        `${twilioServer.baseUrl}/workers/tokens`,
        {
          applicationSid: twilioEnv.appSid,
          endpointId: state.endpointId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.jwtToken}`,
          },
        }
      )
      .then((res) => {
        const { tokens } = res.data;

        if (!tokens) {
          throw new Error('Pas de tokens pour à cet utilisateur');
        }
        // Update token
        taskRouter.updateToken(tokens.capability);
        dispatch('InitializePhone', tokens.access);
        commit('setWorkerToken', tokens.capability);
      })
      .catch((err) => {
        commit('errorTaskRouter', err);
        console.error(err);
      });
  },
  createWorkerTaskCall: ({ commit }, phoneNumber) => {
    axios
      .post(
        `${twilioServer.baseUrl}/phone/create-task-call/${encodeURIComponent(
          phoneNumber
        )}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log('res.body', res.body);
      })
      .catch((err) => {
        commit('errorTaskRouter', err);
        console.error(err);
      });
  },
};

const mutations = {
  setWorker: (state, worker) => {
    const {
      activityName,
      activitySid,
      attributes,
      available,
      friendlyName,
      sid,
    } = worker;
    state.activityName = activityName;
    state.activitySid = activitySid;
    state.attributes = attributes;
    state.available = available;
    state.friendlyName = friendlyName;
    state.sid = sid;
  },
  connectTaskRouter: (state) => {
    state.taskRouterReady = true;
    state.taskRouterError = null;
  },
  disconnectTaskRouter: (state) => {
    state.taskRouterReady = false;
  },
  errorTaskRouter: (state, error) => {
    state.taskRouterError = error;
  },
  setActivityList: (state, activityList) => {
    state.activityList = activityList;
  },
  setWorkerToken: (state, token) => {
    state.token = token;
  },
  updateActivity: (state, activity) => {
    const { activityName, activitySid, available } = activity;
    state.activityName = activityName;
    state.activitySid = activitySid;
    state.available = available;
  },
  resetWorker: (state) => {
    state.friendlyName = null;
    state.sid = null;
    state.token = null;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
