<template>
  <div>
    <el-card shadow="never" class="card-gutter" body-style="padding: 10px;">
      <span class="action-card-title">Demande de rappel</span>
      <el-form
        ref="rappel"
        v-model="rappel"
        label-position="left"
        label-width="120px"
        size="small"
      >
        <el-form-item label="Date et heure de rappel">
          <el-date-picker
            v-model="rappel.dateRappel"
            type="datetime"
            placeholder="Choisir le jour et l'heure"
            default-time="09:00:00"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Commentaires">
          <el-input v-model="rappel.comment" type="textarea"> </el-input>
        </el-form-item>
        <el-form-item>
          <div class="button-left">
            <el-button type="text" native-type="reset">ANULLER</el-button>
            <el-button
              type="primary"
              icon="el-icon-upload"
              @click="onAddCallback"
              >Ajouter un rappel
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card shadow="never" class="card-gutter" body-style="padding: 10px;">
      <span class="action-card-title">Prise de Rendez-vous</span>
      <el-form label-position="left" label-width="120px" size="small">
        <el-form-item label="Intervention">
          <el-select
            v-model="rdv.raison"
            placeholder="Veuillez faire votre choix"
          >
            <el-option
              v-for="item in rdvOPtions"
              :key="item.id"
              :label="item.title"
              :value="item.title"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Titre RDV">
          <el-input v-model="rdv.titre" placeholder="Titre RDV"></el-input>
        </el-form-item>
        <el-form-item label="Lieu du RDV">
          <el-input v-model="rdv.lieu" placeholder="Lieu du RDV"></el-input>
        </el-form-item>
        <el-form-item label="Date et heure">
          <el-date-picker
            v-model="rdv.dateRdv"
            type="datetime"
            placeholder="Choisir le jour et l'heure"
            default-time="09:00:00"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Commentaires">
          <el-input v-model="rdv.comment" type="textarea"> </el-input>
        </el-form-item>
        <el-form-item>
          <add-to-calendar
            :title="rdv.titre"
            :location="rdv.lieu"
            :start="timestampToDate"
            :end="endTime"
            :details="commentBuild"
            inline-template
          >
            <google-calendar id="google-calendar">
              <div class="button-left">
                <el-button type="text" native-type="reset">ANULLER</el-button>
                <el-button type="primary" icon="el-icon-date">
                  <span>Ajouter un RDV</span>
                </el-button>
              </div>
            </google-calendar>
          </add-to-calendar>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ActionTabPane',
  data() {
    return {
      input3: '',
      select: '1',
      dateRdv: '',
      rappel: {
        dateRappel: '',
        comment: '',
      },
      rdv: {
        titre: '',
        lieu: '',
        dateRdv: '',
        comment: '',
        raison: '',
      },
      rdvOPtions: [
        {
          id: '1',
          title: 'SERRURERIE',
        },
        {
          id: '2',
          title: 'PLOMBERIE',
        },
        {
          id: '3',
          title: 'ELECTRICITE',
        },
        {
          id: '4',
          title: 'PEINTURE',
        },
        {
          id: '5',
          title: 'MENUISERIE BOIS/ALU',
        },
        {
          id: '6',
          title: 'RENOVATION',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getWorkerContacUri', 'getSelectedTask']),
    timestampToDate: function() {
      if (this.rdv.dateRdv) {
        return new Date(this.rdv.dateRdv);
      }
      return new Date();
    },
    endTime: function() {
      if (this.rdv.dateRdv) {
        return new Date(this.rdv.dateRdv + 3600000);
      }
      return new Date();
    },
    commentBuild: function() {
      return this.rdv.raison + ': ' + this.rdv.comment;
    },
  },
  methods: {
    ...mapActions(['createCallback']),
    onAddCallback() {
      const { attributes } = this.getSelectedTask;
      const contact_uri = this.getWorkerContacUri;
      const callbackDate = new Date(this.rappel.dateRappel);
      const comment = this.rappel.comment;
      const phone = attributes.phone;
      const name = attributes.name;
      this.createCallback({
        callbackDate,
        comment,
        phone,
        name,
        contact_uri,
      })
        .then((res) => {
          this.$alert(
            'Votre demande a bien été enregistré !',
            'Demande de rappel',
            {
              confirmButtonText: 'OK',
              callback: (action) => {
                this.$refs['rappel'].resetFields();
              },
            }
          );
        })
        .catch((err) => {
          this.$alert(`message: ${(JSON.stringify(err), 'ERREUR')}`, {
            confirmButtonText: 'OK',
            type: 'error',
          });
        });
    },
  },
};
</script>

<style>
.el-select .el-input {
  width: 200px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.card-gutter {
  margin-bottom: 20px;
}
.card-gutter .el-form {
  margin-bottom: 1vh;
}
.button-left {
  float: right;
}
.action-card-title {
  display: block;
  margin-block-start: 0.5em;
  margin-block-end: 1em;
  font-weight: bold;
}
</style>
