<template> </template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Loading',
  computed: {
    ...mapGetters(['isLoading']),
  },
};
</script>

<style></style>
