import { twilioServer, taskRouterEnv } from '../../env-handler';
import axios from 'axios';

const state = {
  clientList: {},
  selectedClient: '',
};

const getters = {
  getSelectedClient: (state) => state.clientList[state.selectedClient],
};

const actions = {
  getClientById: ({ commit }, { clientId }) => {
    console.log('clientId: ', clientId);
    axios
      .get(`${twilioServer.baseUrl}/client`, {
        headers: {
          Authorization: `Bearer ${localStorage.jwtToken}`,
        },
        params: {
          _id: clientId,
        },
      })
      .then((res) => {
        const client = res.data[0];

        commit('addClientToList', client);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  updateClientById: (
    { dispatch },
    { id, phone, name, company, email, address }
  ) => {
    return axios
      .put(
        `${twilioServer.baseUrl}/client/${id}`,
        {
          phone,
          name,
          company,
          email,
          address,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.jwtToken}`,
          },
        }
      )
      .then((res) => {
        dispatch('getClientById', { clientId: id });
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
  selectClient: ({ commit }, clientId) => {
    commit('setSelectedClient', clientId);
  },
};

const mutations = {
  addClientToList: (state, client) => {
    state.clientList = { ...state.clientList, [client.id]: client };
    state.selectedClient = client.id;
  },
  setSelectedClient: (state, clientId) => {
    state.selectedClient = clientId;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
