const state = {
  loading: false,
  leftDrawerMini: true,
  drawerMenu: {
    /* tableauDeBord: {
      title: 'Mon tableau de bord',
      icon: 'el-icon-data-analysis',
      to: '/tableaudebord',
      badge: 0,
    }, */
    taches: {
      title: 'Mes taches',
      icon: 'el-icon-receiving',
      to: '/taches',
      badge: 0,
    },
    /* appeler: {
      title: 'Passer des appels',
      icon: 'el-icon-phone',
      to: '/appeler',
      badge: 0,
    }, */
    calendar: {
      title: 'Calendrier',
      icon: 'el-icon-date',
      to: '/calendar',
      badge: 0,
    },
  },
};

const getters = {
  isLoading: (state) => state.loading,
  leftDrawerIsMinimize: (state) => state.leftDrawerMini,
  getDrawerMenu: (state) => state.drawerMenu,
};

const actions = {
  toggleLeftDrawer: ({ commit }) => {
    commit('toggleLeftDrawer');
  },
  badgeCountUpdate: ({ commit }, { name, count }) => {
    commit('badgeCountUpdate', { name, count });
  },
};

const mutations = {
  setLoading: (state, loading) => {
    state.loading = loading;
  },
  toggleLoading: (state) => {
    state.loading = !state.loading;
  },
  toggleLeftDrawer: (state) => {
    state.leftDrawerMini = !state.leftDrawerMini;
  },
  badgeCountUpdate: (state, { name, count }) => {
    state.drawerMenu[name].badge = count;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
