<template>
  <el-container v-if="!!getSelectedTask" style="height: 100%;">
    <el-header class="alignItemCenter">
      <span>{{ getSelectedTask.attributes.name }}</span>
      <el-button
        v-if="showActionButton"
        type="danger"
        icon="el-icon-circle-close"
        size="small"
        @click="taskAction1"
        >Tache terminée</el-button
      >
    </el-header>
    <el-main class="noPadding">
      <el-tabs type="border-card" style="width: 100%">
        <el-tab-pane label="ACTION">
          <ActionTabPane />
        </el-tab-pane>
        <el-tab-pane>
          <div slot="label">
            <el-badge class="item">
              <span>CLIENT</span>
            </el-badge>
          </div>
          <ClientTabPane />
        </el-tab-pane>
        <el-tab-pane>
          <div slot="label">
            <el-badge class="item">
              <span>APPELS</span>
            </el-badge>
          </div>
          [En cours de développement] <br />
          Si il y a deja eu des appels pour ce numeros. Affiche la liste d'appel
          du client ainsi que les demande de rappel.
        </el-tab-pane>
        <el-tab-pane>
          <div slot="label">
            <el-badge :is-dot="false" class="item">
              <span>RDV</span>
            </el-badge>
          </div>
          [En cours de développement] <br />
          Si il y a deja eu des RDV. Affiche la liste des RDV passé et à venir.
        </el-tab-pane>
      </el-tabs>
    </el-main>
    <el-dialog
      title="Clotûre de la tache"
      :visible.sync="dialogVisible"
      width="30%"
      top="30vh"
    >
      <h4 style="word-break: normal;">
        Afin de valider la fin de cette tache, veuillez indiquer la raison de sa
        cloture.
      </h4>
      <el-input
        type="textarea"
        maxlength="150"
        show-word-limit
        rows="5"
        v-model="raison"
      >
      </el-input>
      <span slot="footer">
        <el-button type="text" @click="dialogVisible = false"
          >Annuler</el-button
        >
        <el-button
          type="primary"
          @click="
            () => {
              completeTask();
              dialogVisible = false;
            }
          "
          >Confirmer</el-button
        >
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ClientTabPane from '../../components/ClientTabPane';
import ActionTabPane from '../../components/ActionTabPane';

export default {
  name: 'AgentRightPanel',
  data() {
    return {
      dialogVisible: false,
      raison: '',
    };
  },
  components: {
    ClientTabPane,
    ActionTabPane,
  },
  computed: {
    ...mapGetters(['getSelectedTask']),
    showActionButton() {
      if (
        (this.getSelectedTask.assignmentStatus == 'assigned' &&
          !this.getSelectedTask.attributes.conference) ||
        this.getSelectedTask.assignmentStatus == 'wrapping'
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(['setCompleteTask', 'getClientById']),
    taskAction1: function() {
      console.log('selected task: ', this.getSelectedTask);
      // this.setCompleteTask(this.getSelectedTask.sid);
      this.dialogVisible = true;
    },
    completeTask: function() {
      const task = this.getSelectedTask;
      this.setCompleteTask({
        taskId: task.sid,
        client: task.attributes.clientId,
        taskChannel: task.taskChannelSid,
        channel: task.attributes.channel,
        attributes: {
          clientId: task.attributes.clientId,
          title: task.attributes.title,
          text: task.attributes.text,
          channel: task.attributes.channel,
          name: task.attributes.name,
          service: task.attributes.service,
          phone: task.attributes.phone,
          metadata: task.attributes.metadata,
        },
        finalComment: this.raison,
      });
    },
  },
};
</script>

<style>
.alignItemCenter {
  align-items: center;
  height: 40px !important;
  line-height: 40px;
}
.item .el-badge__content {
  margin-top: 10px;
  margin-right: -10px;
}
.action-footer {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-left: 1px solid #e6e6e6;
  padding: 20px 20px 20px 20px !important;
  height: auto !important;
}
</style>
