<template>
  <el-dialog
    title="Paramêtre audio"
    :visible.sync="showDialog"
    width="450px"
    @close="closeDialog"
    id="diagDevice"
  >
    <el-form label-position="top" class="device-form" size="small">
      <el-form-item label="Microphone">
        <el-select
          :value="getAudioInput"
          @change="(value) => setInputDevice(value)"
        >
          <el-option
            v-for="device in getAvailableInput"
            :key="device.id"
            :label="device.label"
            :value="device.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Sortie audio">
        <el-select
          :value="getAudioOutput"
          @change="(value) => setOutputDevice(value)"
        >
          <el-option
            v-for="device in getAvailableOutput"
            :key="device.id"
            :label="device.label"
            :value="device.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="closeDialog">Fermer</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DialogDeviceSetup',
  computed: mapGetters([
    'getAvailableInput',
    'getAudioInput',
    'getAvailableOutput',
    'getAudioOutput',
  ]),
  props: {
    showDialog: Boolean,
    closeDialog: Function,
  },
  methods: {
    ...mapActions(['setInputDevice', 'setOutputDevice']),
  },
};
</script>

<style>
.el-dialog__header {
  border-bottom: 1px solid #e6e6e6;
}
#diagDevice .dialog-footer {
  display: flex;
  justify-content: flex-end;
}
#diagDevice .el-form {
  margin-bottom: 0px !important;
}
.device-form .el-select {
  width: 100%;
}
.device-form .el-select .el-input {
  width: 100%;
}
</style>
