<template>
  <el-aside
    width="auto"
    style="border-right: solid 1px #e6e6e6;"
    class="navDrawer"
    id="asideDrawer"
  >
    <el-menu
      @select="selectMenu"
      :default-active="getWorkerActivitySid"
      :collapse="leftDrawerIsMinimize"
    >
      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-user-solid" :style="{ color: badgeActiveColor }">
          </i>
          <span> {{ loginName }} </span>
        </template>
        <el-menu-item
          v-for="atcivity in getActivityList"
          :key="atcivity.sid"
          :index="atcivity.sid"
          :disabled="!isLoggedin"
        >
          {{ atcivity.friendlyName }}
        </el-menu-item>
        <el-menu-item
          index="-1"
          :disabled="!isLoggedin"
          style="border-top: 1px solid #e6e6e6; border-bottom: 1px solid #e6e6e6;"
        >
          <i class="el-icon-circle-close"></i>
          <span>Déconnexion</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
    <el-menu default-active="/taches" :collapse="leftDrawerIsMinimize" router>
      <el-menu-item
        v-for="item in getDrawerMenu"
        :key="item.title"
        :index="item.to"
        :disabled="!isLoggedin"
      >
        <el-badge
          :value="item.badge"
          :hidden="item.badge > 0 ? false : true"
          class="drawer-nav"
        >
          <i :class="item.icon"></i>
        </el-badge>
        <span>{{ item.title }}</span>
      </el-menu-item>
    </el-menu>
    <el-divider style="margin: 0px;"></el-divider>
    <el-menu :collapse="leftDrawerIsMinimize">
      <el-submenu index="3">
        <template slot="title">
          <i class="el-icon-setting"> </i>
          <span>Configuration</span>
        </template>
        <el-menu-item
          index="-3"
          :disabled="!isLoggedin"
          @click="showAudioDevice"
        >
          <i class="el-icon-service"></i>
          <span>Paramètre audio</span>
        </el-menu-item>
        <el-menu-item
          index="-3"
          :disabled="!isLoggedin"
          @click="onClickCalendarSetup"
        >
          <i class="el-icon-date"></i>
          <span>Calendrier Google</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
    <DialogDeviceSetup
      :showDialog="showDeviceSetup"
      :closeDialog="closeAudioDialog"
    />
    <DialogCalendarSetup
      :showDialog="showCalendarSetup"
      :closeDialog="closeCalendarSetup"
    />
  </el-aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DialogDeviceSetup from '../components/DialogDeviceSetup';
import DialogCalendarSetup from '../components/DialogCalendarSetup.vue';

export default {
  name: 'NavigationDrawer',
  data() {
    return {
      showDeviceSetup: false,
      showCalendarSetup: false,
    };
  },
  components: {
    DialogDeviceSetup,
    DialogCalendarSetup,
  },
  computed: {
    ...mapGetters([
      'isLoggedin',
      'getUserEmail',
      'workerIsConnected',
      'leftDrawerIsMinimize',
      'getWorkerFriendlyName',
      'getWorkerActivitySid',
      'getActivityList',
      'getDrawerMenu',
      'taskRouterStatus',
    ]),
    loginName() {
      if (this.workerIsConnected) {
        return this.getWorkerFriendlyName;
      }
      return this.getUserEmail;
    },
    badgeActiveColor() {
      if (this.taskRouterStatus) {
        return 'green';
      }
      return 'red';
    },
  },
  methods: {
    ...mapActions(['changeWorkerActivity', 'toggleLeftDrawer', 'signout']),
    selectMenu: function(key) {
      switch (key) {
        case '-1':
          this.signout();
          break;
        default:
          this.changeWorkerActivity(key);
          break;
      }
    },
    showAudioDevice: function() {
      this.showDeviceSetup = !this.showDeviceSetup;
    },
    closeAudioDialog: function() {
      this.showDeviceSetup = false;
    },
    onClickCalendarSetup: function() {
      this.showCalendarSetup = !this.showCalendarSetup;
    },
    closeCalendarSetup: function() {
      this.showCalendarSetup = false;
    },
  },
};
</script>

<style>
#asideDrawer .el-aside {
  width: auto;
  overflow: hidden !important;
}
.navDrawer .el-menu--popup {
  padding: 0px !important;
}
.navDrawer .el-menu {
  border-right: none;
}
.navDrawer .el-divider--horizontal {
  margin: 0px;
}
</style>
