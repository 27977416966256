import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';

import LoginPage from './pages/LoginPage.vue';
import CallingPage from './pages/CallingPage.vue';
import DashBoard from './pages/DashBoard.vue';
import CalendarPage from './pages/CalendarPage.vue';

// Task pages import
import TaskPage from './pages/task_page/TaskPage.vue';
import AgentTaskList from './pages/task_page/AgentTaskList.vue';
import AgentTaskActions from './pages/task_page/AgentTaskActions.vue';
import AgentRightPanel from './pages/task_page/AgentRightPanel.vue';
import PhoneBooth from './components/CallingTabPane.vue';

import NotFound from './pages/404.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: {
        name: 'taches',
      },
    },
    {
      path: '/signin',
      name: 'signin',
      component: LoginPage,
      beforeEnter: (to, from, next) => {
        const isLoggedin = store.getters.isLoggedin;
        if (isLoggedin) {
          next({ name: 'taches' });
        } else next();
      },
    },
    { path: '/appeler', name: 'appeler', component: CallingPage },
    {
      path: '/taches',
      component: TaskPage,
      children: [
        {
          path: '',
          name: 'taches',
          components: {
            AgentLeftPanel: AgentTaskList,
            AgentCenterPanel: AgentTaskActions,
            AgentRightPanel,
            PhonePanel: PhoneBooth,
          },
        },
      ],
    },
    { path: '/tableaudebord', name: 'tableauDeBord', component: DashBoard },
    { path: '/calendar', name: 'calendarPage', component: CalendarPage },
    { path: '/notfound', name: 'notFound', component: NotFound },
    {
      path: '*',
      redirect: {
        name: 'notFound',
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isLoggedin = store.getters.isLoggedin;
  if (to.name !== 'signin' && !isLoggedin) {
    next({ name: 'signin' });
  } else next();
});

export default router;
