<template>
  <el-container v-if="!!getSelectedTask" style="height: 100%">
    <el-main class="noPadding">
      <el-tabs type="border-card" style="width: 100%">
        <el-tab-pane
          label="APPEL ENTRANT"
          v-if="getSelectedTask.attributes.channel == 'phone'"
        >
          <CallTabPane />
        </el-tab-pane>
        <el-tab-pane
          label="RAPPEL"
          v-else-if="getSelectedTask.attributes.channel == 'callback'"
        >
          <CallTabPane />
        </el-tab-pane>
        <el-tab-pane
          label="APPEL SORTANT"
          v-else-if="getSelectedTask.attributes.channel == 'call'"
        >
          <CallTabPane />
        </el-tab-pane>
        <el-tab-pane label="INFO">
          Information sur le contact

          <hr />

          <json-viewer
            :value="getJsonData"
            :expand-depth="5"
            copyable
            sort
          ></json-viewer>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CallTabPane from '../../components/CallTabPane';
import JsonViewer from 'vue-json-viewer';

export default {
  name: 'AgentTaskActions',
  components: {
    CallTabPane,
    JsonViewer,
  },
  computed: {
    ...mapGetters(['getSelectedTask']),
    showActionButton() {
      if (
        (this.getSelectedTask.assignmentStatus == 'assigned' &&
          !this.getSelectedTask.attributes.conference) ||
        this.getSelectedTask.assignmentStatus == 'wrapping'
      ) {
        return true;
      }
      return false;
    },
    getJsonData() {
      let metadata = this.getSelectedTask.attributes.metadata;
      if (metadata) {
        metadata = JSON.parse(metadata);
        return { ...this.getSelectedTask.attributes, metadata };
      }
      return { ...this.getSelectedTask.attributes };
    },
  },
  methods: {
    ...mapActions(['setCompleteTask']),
    taskAction1: function() {
      console.log('selected task: ', this.getSelectedTask);
      this.setCompleteTask(this.getSelectedTask.sid);
    },
  },
};
</script>

<style>
.noPadding {
  padding: 0px !important;
  display: flex !important;
}
.alignItemCenter {
  align-items: center;
}
</style>
