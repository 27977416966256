<template>
  <iframe
    :src="getGoogleCalendar"
    width="95%"
    height="95%"
    frameborder="0"
    scrolling="no"
  ></iframe>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CalendarPage',
  computed: mapGetters(['getGoogleCalendar']),
};
</script>

<style></style>
