<template>
  <el-container class="main-container">
    <AppTopBar />
    <el-container>
      <NavigationDrawer />
      <el-main class="el-main-page">
        <router-view> </router-view>
        <Loading />
        <DialogHorsLigne />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import AppTopBar from './components/AppTopBar';
import Loading from './components/Loading';
import NavigationDrawer from './components/NavigationDrawer';
import DialogHorsLigne from './components/DialogBox';

export default {
  name: 'App',

  components: {
    AppTopBar,
    Loading,
    NavigationDrawer,
    DialogHorsLigne,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
html,
body {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 0px;
  height: 100%;
  margin: 0;
}
.main-container {
  height: 100%;
  flex-direction: column !important;
}
.el-main-page {
  padding: 0px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
</style>
