import Vuex from 'vuex';
import Vue from 'vue';
import auth from './modules/auth';
import worker from './modules/worker';
import ui from './modules/ui';
import tasks from './modules/tasks';
import phone from './modules/phone';
import client from './modules/client';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    worker,
    ui,
    tasks,
    phone,
    client,
  },
});
