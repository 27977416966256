<template>
  <el-col>
    <el-card shadow="hover" body-style="padding: 0px; height: 54px;">
      <div class="task-card">
        <div
          class="task-icon-div"
          :style="
            `background-color: ${taskRender(task.taskQueueSid).iconBackground};`
          "
        >
          <ion-icon
            :name="taskRender(task.taskQueueSid).icon"
            :style="
              `width: 54px; color: ${taskRender(task.taskQueueSid).iconColor};`
            "
            class="task-icon"
          ></ion-icon>
        </div>
        <div
          class="task-text-div"
          :class="selectedTaskId != task.sid ? 'not-active-item' : ''"
          @click="() => selectTask(task.sid)"
        >
          <span>{{ task.attributes.title }}</span>
          <small style="color: grey">
            Créé {{ fromNow(task.dateCreated) }}
          </small>
        </div>
      </div>
    </el-card>
  </el-col>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import { taskRouterEnv } from '../env-handler';

export default {
  name: 'TaskListItem',
  props: {
    task: Object,
    selectedTaskId: String,
  },
  methods: {
    ...mapActions(['selectTask']),
    taskRender: function(taskQueueSid) {
      return taskRouterEnv.TaskQueues[taskQueueSid];
    },
    fromNow: function(date) {
      // reservation.task.dateCreated
      return moment(date).fromNow();
    },
  },
};
</script>

<style>
.not-active-item {
  background-color: #f2f6fc;
}
</style>
