// import { Device } from 'twilio-client';
import { Device } from '@twilio/voice-sdk';
import { EventEmitter } from 'events';

export default class TwilioClient {
  constructor(token, commit, dispatch) {
    this._taskId = null;

    this._callEvents = new EventEmitter();
    commit('callEvents', this._callEvents);

    this.device = new Device(token, {
      codecPreferences: ['opus', 'pcmu'],
      logLevel: 'debug',
    });
    this.device.register().then((device) => {
      commit('setAvailableInput', this.availableInput);
      commit('setAvailableOutput', this.availableOutput);

      this.device.audio.setInputDevice('default').then(() => {
        commit(
          'setAudioInput',
          this.device.audio.availableInputDevices.get('default').label
        );
      });

      this.device.audio.speakerDevices.set('default').then(() => {
        commit(
          'setAudioOutput',
          this.device.audio.availableOutputDevices.get('default').label
        );
      });

      // Set audio on event
      this._callEvents.on('audio', (action, params, cb) => {
        try {
          if (action == 'setInputDevice') {
            this.device.audio.setInputDevice(params).then((error) => {
              commit(
                'setAudioInput',
                this.device.audio.availableInputDevices.get(params).label
              );
              return cb();
            });
          } else {
            this.device.audio.speakerDevices.set(params).then((error) => {
              commit(
                'setAudioOutput',
                this.device.audio.availableOutputDevices.get(params).label
              );
              return cb();
            });
          }
        } catch (error) {
          console.log('Error: ', error);
        }
      });
    });

    // Incoming call event
    this.device.on('incoming', (call) => {
      console.log('New incoming Call', call.status());
      dispatch('incomingCall', call.parameters.CallSid);
      commit('setCallStatus', 'pending');

      this._callEvents.on(this._taskId, async (action, params, callback) => {
        console.log('Calling status: ', call.status());
        await call[action](params);
        return callback(call.status());
      });

      call.on('accept', (call) => {
        console.log('Call has been accepted', call.status());
        commit('setCallStatus', 'open');
      });

      call.on('disconnect', (call) => {
        console.log('Caller disconnected');
        commit('setCallStatus', 'closed');
      });

      call.on('cancel', () => {
        console.log('Call has been canceled');
        commit('setCallStatus', 'closed');
        //commit('setIncomingCall', null);
      });

      call.on('mute', (isMuted, call) => {
        console.log('Caller is muted');
        commit('setMuteStatus', isMuted);
      });

      call.on('reject', () => {
        console.log('Caller reject');
        commit('setCallStatus', 'closed');
      });
      call.on('error', (error) => {
        console.log('Device error: ', error);
        if (error.code == 31205) {
          dispatch('renewWorkerToken');
        }
        commit('setCallStatus', 'closed');
      });
    });
  }

  disconnectAll() {
    this._taskId = null;
    this._callEvents.removeAllListeners();
    this.device.disconnectAll();
  }

  /**
   * @param {string} taskid
   */
  set taskId(taskid) {
    // set new taskId and remove old listener
    this._taskId = taskid;
    this._callEvents.removeAllListeners();
  }

  get availableInput() {
    let inputList = [];
    this.device.audio.availableInputDevices.forEach((value, id) => {
      inputList = [...inputList, { id, label: value.label }];
    });
    return inputList;
  }

  get availableOutput() {
    let outputList = [];
    this.device.audio.availableOutputDevices.forEach((value, id) => {
      outputList = [...outputList, { id, label: value.label }];
    });
    return outputList;
  }
}
