<template>
  <div class="taskpage-container">
    <div class="v-split-1 left-pane">
      <div class="h-split-1 top">
        <router-view name="AgentLeftPanel" />
      </div>
      <div class="h-splitter"></div>
      <div class="h-split-1 bottom">
        <router-view name="PhonePanel" />
      </div>
    </div>
    <div class="v-splitter"></div>
    <div class="v-split-1 center-pane">
      <router-view name="AgentCenterPanel" />
    </div>
    <div class="v-splitter"></div>
    <div class="v-split-1 right-pane">
      <router-view name="AgentRightPanel" />
    </div>
  </div>
</template>

<script>
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

export default {
  name: 'TasksPage',
  components: {
    Splitpanes,
    Pane,
  },
};
</script>
<style>
.taskpage-container {
  background-color: #f2f2f2;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
}
.taskpage-container .v-splitter {
  width: 2px;
  background-color: #dcdfe6;
}
.taskpage-container .left-pane {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.taskpage-container .center-pane {
  width: 350px;
}
.taskpage-container .right-pane {
  flex-grow: 1;
}
.taskpage-container .left-pane .top {
  align-self: stretch;
  flex-grow: 1;
}
.taskpage-container .h-splitter {
  background-color: #dcdfe6;
  width: 100%;
  height: 2px;
}
</style>
