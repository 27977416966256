import { EventEmitter } from 'events';
import { taskRouterEnv, twilioEnv, twilioServer } from '../env-handler';

export default class TwilioTaskRouter {
  constructor(token, commit, dispatch) {
    // Twilio loaded directly in index.html
    const loadWorker = new Twilio.TaskRouter.Worker(
      token,
      taskRouterEnv.debug,
      taskRouterEnv.connectActivitySid,
      taskRouterEnv.disconnectActivitySid,
      true // disconnect existing websockets for the same Worker upon Connecting.
    );

    const taskEvents = new EventEmitter();
    commit('taskEvents', taskEvents);

    loadWorker.on('ready', (worker) => {
      this._worker = loadWorker;
      this.workerFriendlyName = worker.friendlyName;

      commit('connectTaskRouter');
      commit('setWorker', worker);

      this.fetchActivities((err, activityList) => {
        if (err) {
          commit('errorTaskRouter', err);
        }
        commit('setActivityList', activityList);
      });

      this.fetchReservation((err, Reservations) => {
        if (err) {
          commit('errorTaskRouter', err);
        }
        if (Reservations.length) {
          dispatch('initialTasklList', Reservations);
        }
      });
    });

    loadWorker.on('disconnected', (error) => {
      this._worker = null;
      commit('disconnectTaskRouter');
    });

    loadWorker.on('error', (error) => {
      commit('errorTaskRouter', error);
    });

    loadWorker.on('activity.update', (activity) => {
      commit('updateActivity', activity);
      if (activity.activitySid == taskRouterEnv.disconnectActivitySid) {
        dispatch('offline');
      }
    });

    // Trigger only on task attributes modification
    loadWorker.on('task.updated', (task) => {
      dispatch('taskUpdateReq', task);
    });
    loadWorker.on('task.canceled', (task) => {
      dispatch('taskUpdateReq', task);
    });
    loadWorker.on('task.completed', (task) => {
      dispatch('taskUpdateReq', task);
    });
    loadWorker.on('task.wrapup', (task) => {
      dispatch('taskUpdateReq', task);
    });

    loadWorker.on('reservation.created', (reservation) => {
      // Creation de la taches
      dispatch('taskListUpdateReq', reservation.task);

      switch (reservation.task.attributes.channel) {
        case 'phone':
          dispatch('TaskIdIncomingCall', {
            taskId: reservation.task.sid,
            cb: () => {
              const options = {
                ConferenceRecord: 'false',
                EndConferenceOnExit: 'true',
                EndConferenceOnCustomerExit: 'true',
              };
              reservation.conference(null, null, null, null, null, options);
              dispatch('selectTask', reservation.task.sid);
            },
          });
          break;
        case 'callback':
          dispatch('TaskIdIncomingCall', {
            taskId: reservation.task.sid,
            cb: () => {
              reservation.call(
                // callFrom
                twilioEnv.phoneNumber,
                // TwiML URI that is executed on the answering Worker's leg.
                `${twilioServer.baseUrl}/phone/call/${reservation.task.attributes.phone}?token=${localStorage.jwtToken}&reservation=${reservation.sid}`,
                null,
                null,
                null,
                null,
                (error, reservation) => {
                  if (error) {
                    commit('errorTaskRouter', err);
                  } else {
                    dispatch('selectTask', reservation.task.sid);
                  }
                }
              );
            },
          });
          break;
        case 'call':
          dispatch('TaskIdIncomingCall', {
            taskId: reservation.task.sid,
            cb: () => {
              reservation.call(
                // callFrom
                twilioEnv.phoneNumber,
                // TwiML URI that is executed on the answering Worker's leg.
                `${twilioServer.baseUrl}/phone/call/${reservation.task.attributes.phone}?token=${localStorage.jwtToken}&reservation=${reservation.sid}`,
                null,
                null,
                null,
                null,
                (error, reservation) => {
                  if (error) {
                    commit('errorTaskRouter', err);
                  } else {
                    dispatch('selectTask', reservation.task.sid);
                  }
                }
              );
            },
          });
          break;
        case 'video':
          reservation.accept();
          break;
        case 'chat':
          reservation.accept();
          break;
        default:
          reservation.accept();
          break;
      }
    });

    loadWorker.on('reservation.accepted', (reservation) => {
      dispatch('taskUpdateReq', reservation.task);
    });

    loadWorker.on('reservation.rescinded', (reservation) => {
      //dispatch('addReservationToList', reservation);
    });

    loadWorker.on('reservation.timeout', (reservation) => {
      //dispatch('removeReservationToList', reservation);
    });

    loadWorker.on('reservation.rejected', (reservation) => {
      //dispatch('removeReservationToList', reservation);
    });

    loadWorker.on('token.expired', () => {
      console.log('TaskRouter token expired...');
      dispatch('renewWorkerToken');
    });
  }

  get worker() {
    if (!this._worker) {
      throw new Error('TaskRouter deconnecté');
    }
    return this._worker;
  }

  fetchActivities(callback) {
    this.worker.activities.fetch((error, { data }) => {
      if (error) {
        return callback(error);
      }
      const activityList = data.map((activity) => {
        return {
          available: activity.available,
          friendlyName: activity.friendlyName,
          sid: activity.sid,
        };
      });
      return callback(null, activityList);
    });
  }

  fetchReservation(callback) {
    this.worker.fetchReservations(
      (error, { data }) => {
        if (error) {
          return callback(error);
        }
        return callback(null, data);
      },
      { ReservationStatus: 'accepted' }
    );
  }

  setWorkerActivity(updateActivitySid, callback) {
    this.worker.update('ActivitySid', updateActivitySid, (error) => {
      if (error) {
        return callback(error);
      }
      return callback(null);
    });
  }

  setCompleteTask(taskId, callback) {
    this.worker.completeTask(taskId, (error) => {
      if (error) {
        return callback(error);
      }
      return callback(null);
    });
  }

  updateToken(newToken) {
    this.worker.updateToken(newToken);
  }
}
