<template>
  <div>
    <el-form ref="clientForm" :model="clientForm" label-width="120px">
      <el-form-item label="ID client">
        <el-input v-model="clientForm.id" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="Nom Complet">
        <el-input v-model="clientForm.name"></el-input>
      </el-form-item>
      <el-form-item label="Société">
        <el-input v-model="clientForm.company"></el-input>
      </el-form-item>
      <el-form-item label="Email">
        <el-input v-model="clientForm.email"></el-input>
      </el-form-item>
      <el-form-item label="Adresse">
        <el-input type="textarea" v-model="clientForm.address"></el-input>
      </el-form-item>
      <el-form-item class="button-left">
        <el-button type="primary" :disabled="toSave" @click="onSubmit"
          >Enregistrer</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ClientTabPane',
  data() {
    return {
      clientForm: {
        id: '',
        name: '',
        company: '',
        email: '',
        address: '',
      },
      toSave: true,
    };
  },
  computed: {
    ...mapGetters(['getSelectedClient']),
  },
  watch: {
    getSelectedClient: function(value, oldvalue) {
      this.clientForm = { ...value };
    },
    'clientForm.name': function(value) {
      if (value !== this.getSelectedClient.name) {
        this.onSave();
      }
    },
    'clientForm.company': function(value) {
      if (value !== this.getSelectedClient.company) {
        this.onSave();
      }
    },
    'clientForm.email': function(value) {
      if (value !== this.getSelectedClient.email) {
        this.onSave();
      }
    },
    'clientForm.address': function(value) {
      if (value !== this.getSelectedClient.address) {
        this.onSave();
      }
    },
  },
  methods: {
    ...mapActions(['updateClientById']),
    onSubmit: async function() {
      this.toSave = true;
      try {
        await this.updateClientById(this.clientForm);
        this.$message({
          message: 'Sauvegarde des informations du client.',
          type: 'success',
        });
      } catch (error) {
        this.$message.error('Oops, il y a eu une erreur.');
      }
    },
    onSave: function() {
      this.toSave = false;
    },
  },
};
</script>

<style>
.el-select .el-input {
  width: 120px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>
