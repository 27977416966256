<template>
  <div>Calling Page</div>
</template>

<script>
export default {
  name: 'CallingPage',
};
</script>

<style></style>
