<template>
  <el-dialog
    title="Votre status"
    :visible.sync="dialogHorsLigne"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    width="400px"
  >
    <span>
      Vous êtes actuellement indisponible !
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button
        @click="() => changeWorkerActivity(taskRouterEnv.disconnectActivitySid)"
        >Je suis Hors ligne</el-button
      >
      <el-button
        type="primary"
        @click="() => changeWorkerActivity(taskRouterEnv.connectActivitySid)"
        >je suis disponible</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { taskRouterEnv } from '../env-handler';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DialogHorsLigne',
  data() {
    return {
      taskRouterEnv,
    };
  },
  computed: mapGetters(['dialogHorsLigne']),
  methods: {
    ...mapActions(['changeWorkerActivity']),
  },
};
</script>

<style>
.el-dialog__header {
  border-bottom: 1px solid #e6e6e6;
}
.dialog-footer {
  display: flex;
  justify-content: space-around;
}
</style>
