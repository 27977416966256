import { twilioServer, taskRouterEnv } from '../../env-handler';
import axios from 'axios';
import { size } from 'lodash';

const state = {
  reservationList: {},
  taskList: {},
  selectedReservation: null,
  selectedTask: null,
  taskEvents: null,
};

const getters = {
  showTaskList: (state) => size(state.taskList) > 0,
  getTaskList: (state) => state.taskList,
  getSelectedTask: (state) => state.selectedTask,
};

const actions = {
  initialTasklList: ({ state, commit, dispatch }, reservations) => {
    let taskList = {};
    reservations.forEach((res) => {
      taskList = {
        ...taskList,
        [res.task.sid]: res.task,
      };
    });
    commit('setTaskList', taskList);
  },
  selectTask: ({ commit, dispatch }, taskId) => {
    commit('setSelectedTask', taskId);
    if (state.taskList[taskId].attributes.clientId) {
      const clientId = state.taskList[taskId].attributes.clientId;
      dispatch('getClientById', { clientId });
    }
  },
  taskListUpdateReq: ({ commit }, task) => {
    commit('updateTaskList', task);
  },
  taskUpdateReq: ({ commit, dispatch }, task) => {
    if (task.reason == 'hangup') {
      dispatch('rejectCall', task.sid);
    }
    commit('updateTask', {
      taskSid: task.sid,
      attributes: task.attributes,
      assignmentStatus: task.assignmentStatus,
    });
  },
  setCompleteTask: (
    { dispatch },
    { taskId, client, taskChannel, channel, attributes, finalComment }
  ) => {
    axios
      .post(
        `${twilioServer.baseUrl}/contact`,
        {
          contactSid: taskId,
          client,
          taskChannel,
          channel,
          attributes,
          finalComment,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.jwtToken}`,
          },
        }
      )
      .then((res) => {
        dispatch('taskRouterCompletedTask', taskId);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  createCallback: (
    ctx,
    { callbackDate, comment, phone, name, contact_uri }
  ) => {
    const workflowSid = taskRouterEnv.workflows.default.sid;
    return axios.post(
      `${twilioServer.baseUrl}/callback`,
      {
        callbackDate,
        comment,
        phone,
        name,
        contact_uri,
        workflowSid,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.jwtToken}`,
        },
      }
    );
  },
  addConferenceToTask: ({ commit }, { taskId, conference }) => {
    commit('updateConferenceTask', { taskId, conference });
  },
};

const mutations = {
  taskEvents: (state, taskEvents) => {
    state.taskEvents = taskEvents;
  },
  setTaskList: (state, taskList) => {
    state.taskList = taskList;
  },
  setSelectedTask: (state, taskId) => {
    state.selectedTask = state.taskList[taskId];
  },
  updateTaskList: (state, task) => {
    state.taskList = { ...state.taskList, [task.sid]: task };
  },
  updateTask: (state, { taskSid, attributes, assignmentStatus }) => {
    /* state.taskList = {
      ...state.taskList,
      [taskSid]: { ...state.taskList[taskSid], attributes, assignmentStatus },
    }; */
    state.taskList[taskSid].attributes = attributes;
    state.taskList[taskSid].assignmentStatus = assignmentStatus;
  },
  updateConferenceTask: (state, { taskId, conference }) => {
    state.taskList[taskId].attributes = {
      ...state.taskList[taskId].attributes,
      conference,
    };
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
