<template>
  <el-dialog
    title="Paramêtre Google Agenda"
    :visible.sync="showDialog"
    width="450px"
    @close="closeDialog"
    id="diagDevice"
  >
    <el-form label-position="top" class="device-form" size="small">
      <el-form-item label="Code d'intégration Google Agenda">
        <el-input
          type="textarea"
          autosize
          placeholder="Entrez quelque chose"
          v-model="calendarIframe"
          @change="formatGoogleCal"
        >
        </el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="closeDialog">Fermer</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DialogDeviceSetup',
  computed: mapGetters(['getGoogleCalendar']),
  props: {
    showDialog: Boolean,
    closeDialog: Function,
  },
  data() {
    return {
      calendarIframe: this.getGoogleCalendar,
    };
  },
  mounted: function() {
    this.calendarIframe = this.getGoogleCalendar;
  },
  methods: {
    ...mapActions(['googleCalendar']),
    formatGoogleCal: function(cal) {
      const googleCal = cal.split('"');
      if (googleCal[1]) {
        this.googleCalendar(googleCal[1]);
      }
      //
    },
  },
};
</script>

<style>
.el-dialog__header {
  border-bottom: 1px solid #e6e6e6;
}
#diagDevice .dialog-footer {
  display: flex;
  justify-content: flex-end;
}
#diagDevice .el-form {
  margin-bottom: 0px !important;
}
.device-form .el-select {
  width: 100%;
}
.device-form .el-select .el-input {
  width: 100%;
}
</style>
