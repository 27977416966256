import TwilioClient from '../../services/twilio-client-helper';
import { twilioServer } from '../../env-handler';
import axios from 'axios';

let twilioClient = null;

const state = {
  token: null,
  debug: null,
  callEvents: null,
  incomingCallSid: null,
  callStatus: null,
  muteStatus: false,
  holdStatus: false,
  incomingCallTaskId: null,
  availableInput: [],
  availableOutput: [],
  audioInput: '',
  audioOutput: '',
};

const getters = {
  getIncomingCallSid: (state) => state.incomingCallSid,
  getCallStatus: (state) => state.callStatus,
  getMuteStatus: (state) => state.muteStatus,
  getHoldStatus: (state) => state.holdStatus,
  getIncomingCallTaskId: (state) => state.incomingCallTaskId,
  getAvailableInput: (state) => state.availableInput,
  getAudioInput: (state) => state.audioInput,
  getAvailableOutput: (state) => state.availableOutput,
  getAudioOutput: (state) => state.audioOutput,
  getShowCallPad: (state) => state.showCallPad,
};

const actions = {
  InitializePhone: ({ commit, dispatch }, token) => {
    commit('setTwilioClientToken', token);
    twilioClient = new TwilioClient(token, commit, dispatch);
  },
  answerCall: ({ state, commit }, taskId) => {
    state.callEvents.emit(taskId, 'accept', '', (res) => {
      console.log('Answer call', res);
    });
  },
  ignoreCall: ({ state, commit }, taskId) => {
    state.callEvents.emit(taskId, 'ignore', '', (res) => {
      console.log('Ignore call', res);
    });
  },
  rejectCall: ({ state, commit }, taskId) => {
    state.callEvents.emit(taskId, 'reject', '', (res) => {
      console.log('Reject call', res);
    });
  },
  closeCall: ({ state, commit }, taskId) => {
    //twilioClient.disconnectAll();
    state.callEvents.emit(taskId, 'disconnect', '', (res) => {
      console.log('Disconnecting call', res);
    });
  },
  incomingCall: ({ dispatch, commit }, callSid) => {
    commit('setIncomingCall', callSid);
  },
  TaskIdIncomingCall: ({ commit }, { taskId, cb }) => {
    twilioClient.taskId = taskId;
    commit('setTaskIdIncomingCall', taskId);
    cb();
  },
  ParticipantCallOnHold: ({ commit }, { conferenceSid, callSid, hold }) => {
    axios
      .post(
        `${twilioServer.baseUrl}/phone/hold`,
        { conferenceSid, callSid, hold },
        {
          headers: {
            Authorization: `Bearer ${localStorage.jwtToken}`,
          },
        }
      )
      .then((res) => {
        const { participant } = res.data;
        console.log('Participant on hold: ', participant);
        commit('setHoldStatus', participant.hold);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  setInputDevice: ({ state }, inputId) => {
    state.callEvents.emit('audio', 'setInputDevice', inputId, () => {});
  },
  setOutputDevice: ({ state }, outputId) => {
    state.callEvents.emit('audio', 'setOutputDevice', outputId, () => {});
  },
  reqConferenceByName: ({ state }, friendlyName) => {
    return axios
      .get(`${twilioServer.baseUrl}/phone/conference/${friendlyName}`, {
        headers: {
          Authorization: `Bearer ${localStorage.jwtToken}`,
        },
      })
      .then((res) => {
        const { conference } = res.data;
        return conference;
      });
  },
};

const mutations = {
  setTwilioClientToken: (state, token) => {
    state.token = token;
  },
  callEvents: (state, callEvents) => {
    state.callEvents = callEvents;
  },
  setIncomingCall: (state, callSid) => {
    state.incomingCallSid = callSid;
  },
  setCallStatus: (state, callStatus) => {
    state.callStatus = callStatus;
  },
  setMuteStatus: (state, muteStatus) => {
    state.muteStatus = muteStatus;
  },
  setHoldStatus: (state, holdStatus) => {
    state.holdStatus = holdStatus;
  },
  setTaskIdIncomingCall: (state, taskId) => {
    state.incomingCallTaskId = taskId;
  },
  setAvailableInput: (state, availableInputList) => {
    state.availableInput = availableInputList;
  },
  setAvailableOutput: (state, availableOutputList) => {
    state.availableOutput = availableOutputList;
  },
  setAudioInput: (state, audioInputLabel) => {
    state.audioInput = audioInputLabel;
  },
  setAudioOutput: (state, audioOutputLabel) => {
    state.audioOutput = audioOutputLabel;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
