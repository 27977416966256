<template>
  <div>Page introuvable</div>
</template>

<script>
export default {
  name: 'NotFoundPage',
};
</script>

<style></style>
